
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
}


html, body {
  font-family: "Montserrat", Arial, sans-serif;
}


/****************************

STATIC MARCUP STYLES

****************************/

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat', Arial, sans-serif;
}


h2 {
  font-size: 26px;
  font-weight: 600;
}

.container {
  margin: 0 auto;
  padding: 0 18px;
  max-width: 1440px;
}

/* overlay + loader */
.site-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(255, 255, 255, 0.2); */
  user-select: none;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.site-loader img {
  width: 225px;
  height: 225px;
  border-radius: 100%;
  -webkit-animation: rotating 3s linear infinite;
  -moz-animation: rotating 3s linear infinite;
  -ms-animation: rotating 3s linear infinite;
  -o-animation: rotating 3s linear infinite;
  animation: rotating 3s linear infinite;
}

/* site header */
.site-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
}

.site-header__logo {
  font-size: 0;
}

.site-header__logo img {
  width: 60px;
  height: 60px;
}

.site-header__bag img {
  width: 40px;
  height: 40px;
}

@media (min-width: 600px) {
  .site-header {
    padding: 6px 18px;
  }
}

.artboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  /* background-image: url('../images/main-bg.jpeg'); */
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
}

.artboard__logo {
  max-width: 280px;
  width: 100%;
}

.artboard h1 {
  color: #fff;
  margin: 40px 0;
  font-size: 28px;
  font-weight: 500;
}

.btn {
  display: block;
  text-align: center;
  padding: 14px 0;
  border-radius: 0px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;

  text-decoration: none;
}

.btn--clear-white {
  color: #fff;
  padding: 14px 34px;
  border: 2px solid #fff;
  border-radius: 3px;
  background: transparent;
}

.btn--clear-black {
  color: #000;
  border: 2px solid #000;
  border-radius: 3px;
  background: transparent;
  width: 100%;
}

.btn--fill {
  color: #fff;
  background: #000;
  border-radius: 3px;
  border: none;
  width: 100%;
}

.btn--fixed-width {
  width: 250px;
  margin: 0 auto;
}


main {
  margin-top: 32px;
}

.s-cards {
  margin-bottom: 50px;
}

.s-cards__header {
  margin: 54px 0 38px;
}

/* карточки */
.s-card {
  width: 100%;
  margin-bottom: 54px;
}

.s-cards__inner {
  row-gap: 30px;
  margin-bottom: 50px;
}

@media (min-width: 600px) {
  .s-cards__inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 50px;
  }

  .s-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
    width: calc(50% - 20px);
    margin: 0 10px;
  }
}

@media (min-width: 768px) {
  .s-cards__inner {
    margin: 0 -10px 50px;
    row-gap: 40px;
  }

  .s-card {
    width: calc(33.33% - 20px);
    margin: 0 10px;
  }
}

@media (min-width: 1024px) {
  .s-cards__inner {
    row-gap: 50px;
    margin: 0 -15px 50px;
  }

  .s-card {
    width: calc(25% - 30px);
    margin: 0 15px;
  }
}

@media (min-width: 1200px) {
  .s-cards__inner {
    row-gap: 70px;
    margin: 0 -25px 50px;
  }

  .s-card {
    width: calc(25% - 50px);
    margin: 0 25px;
  }
}

.s-card__name {
  font-size: 22px;
  font-weight: 400;
}

.s-card__price {
  font-size: 16px;
  font-weight: 400;
}

.s-card__price-old {
  text-decoration: line-through;
  opacity: 0.6;
}

.s-card img {
  max-height: 100%;
  max-width: 100%;
}

.s-card__btns {
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
}


/* map */

.map {
  display: flex;
  flex-direction: column-reverse;
}

#map {
  width: 100%;
  height: 300px;
}

.map__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 34px 24px;
  background: #292929;
  color: #fff;
}

.map__info h2 {
  margin: 0 0 18px;
  font-size: 20px;
}

.map__info p {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .map {
    display: flex;
    flex-direction: row;
  }

  #map {
    width: 70%;
  }

  .map__info {
    width: 30%;
    padding: 34px 54px;
  }
}

/* contacts */
.contacts {
  text-align: center;
  margin: 30px auto;
}

.contacts a {
  color: #000
}

.contacts h2 {
  font-weight: 300;
  font-size: 20px;
}

.contacts p {
  font-size: 14px;
}

@media (min-width: 1024px) {
  .contacts {
    margin: 60px auto;
  }
  
  .contacts h2 {
    font-weight: 300;
    font-size: 28px;
  }
  
  .contacts p {
    font-size: 18px;
  }
  
}

/* breadcrumbs  */
.breadcrumbs {
  display: flex;
  margin-bottom: 30px;
  column-gap: 10px;
}

.breadcrumbs__item {
  text-decoration: none;
  color: #000;
  font-weight: 300;
}

.breadcrumbs__item:hover {
  opacity: 0.65;
}

/* product page */

.product-page h1 {
  margin-bottom: 20px;
}

.product {
  display: flex;
  flex-direction: column;
}

.product__image {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.product__image img {
  width: 100%;
  height: auto;
}

.product__info h1 {
  margin-top: 0;
}

.product__info h2 {
  font-size: 20px;
}

.product__price {
  font-size: 18px;
  font-weight: 400;
}

.product__price-old {
  text-decoration: line-through;
  opacity: 0.6;
}

.product__info pre {
  margin: 0;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 24px;
  word-break: break-all;
  word-wrap: break-word;
  text-wrap: wrap;
}

@media (min-width: 850px) {
  .product {
    flex-direction: row;
    gap: 40px;
  }

  .product__image {
    width: 30%;
  }
}


@media (min-width: 1024px) {
  .product {
    gap: 80px;
  }

  .product__image {
    width: 40%;
  }
}

/* catalog filters */
.filters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 60px auto 40px;
}


.filters__selects {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.filters__selects .select {
  width: 100%;
}

.filters__search {
  padding: 8px 16px;
  outline: none;
  border: 2px solid #000;
  border-radius: 3px;
  width: 100%;
}

@media (min-width: 1024px) {
  .filters {
    flex-direction: row;
  }

  .filters__selects {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 0;
  }

  .filters__selects .select {
    width: auto;
  }

  .filters__search {
    width: 30%;
  }
}

/* select */

.select {
  position: relative;
}

.select__label {
  padding: 8px 32px 8px 16px;
  border: 2px solid #000;
  border-radius: 3px;
  user-select: none;
  cursor: pointer;
}

.select i {
  position: absolute;
  top: 15px;
  right: 12px;
  font-size: 10px;
}

.select__popup {
  display: none;
  padding: 4px 0;
  border: 2px solid #000;
  border-radius: 3px;
  cursor: pointer;
  background: #fff;
  z-index: 10;
}

.checkbox {
  display: flex;
  gap: 8px;
  padding: 8px 16px;
}

.checkbox label {
  text-wrap: nowrap;
  user-select: none;
  cursor: pointer;
}

.select.active .select__popup {
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
}

/* 404 */
.backend-error {
  text-align: center;
  margin: 25vh auto;
  height: 20vh;
  font-size: 50px;
}

/* site footer */
footer {

}