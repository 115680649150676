
.App {
  position: relative;
  text-align: center;
  max-width: 1200px;
  padding: 28px;
  margin: 0 auto;
  font-family: 'Montserrat', Arial, sans-serif;
}

.tg-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
}


.tg-btn img {
  width: 50px;
  height: 50px;
}

.logo {
  margin: 0 auto;
  width: 100%;
}

.logo img {
  width: auto;
  max-height: 150px;
}

input {
  width: 50%;
  margin: 0 auto 50px 0;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.8);
  /* border-radius: 12px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 100px auto;
  column-gap: 14px;
  max-width: 720px;
}

.cards a {
  text-decoration: none;
  color: #000;
  line-height: 22px;
  font-size: 13px;
}

.card {
  position: relative;
  padding: 40px;
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  cursor: pointer;
  z-index: unset;
}

@media (max-width: 500px) {
  .cards {
    justify-content: center;
  }
}

.card::before {
  position: absolute;
  transform: translateX(0);
  transition: all 0.5s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 100%;
  content: '';
  z-index: -10;
}


.card:hover::before {
  transform: translateX(8px);
  transition: all 0.5s;
}

.card img {
  width: 100%;
  height: 100%;
}

.card.catalog {
  background: #000;
}

.card.tg {
  padding: 45px 47px 42px 40px;
  background: #37AEE2;
}

.card.inst {
  background: radial-gradient(136.19% 136.19% at -19.31% -12.89%, #3771C8 0%, #3771C8 23.38%, rgba(60, 102, 205, 0.9679) 25.84%, rgba(73, 71, 221, 0.8634) 33.85%, rgba(83, 45, 233, 0.749) 42.61%, rgba(92, 25, 243, 0.6231) 52.26%, rgba(98, 11, 250, 0.4797) 63.24%, rgba(101, 2, 254, 0.3037) 76.73%, rgba(102, 0, 255, 0) 100%);
  background-image: radial-gradient(104.81% 104.81% at 19.28% 99.61%, #FFDD55 0%, #FFDD55 10%, #FF543E 50%, #C837AB 100%);;
}

.card.yt {
  padding: 50px;
  background: #ED3024;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.flex > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 18px;
  min-height: 200px;
  width: calc(25% - 55px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  box-shadow: 1px 2px 10px -5px #282c34;
}

.flex div > img {
  max-height: 300px;
  max-width: 100%;
}

.flex > div > div {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: flex-start;
  text-align: left;
}


@media (max-width: 500px) {
  input {
    width: 90%
  }

  .flex > div {
    width: calc(100%);
  }
}
